<template>
  <div>
    <div v-if="! isModal" class="level is-variable bd-klmn-columns is-1 is-marginless">
      <div class="level-left">
        <b-button
          class="filter-button is-hidden-mobile"
          size="is-circle"
          icon-left="filter-remove"
          @click="clearFilter"
        />
        <div class="filter-tags">
          <b-tag
            v-for="(value, index) in filtered"
            :key="index"
            type="is-purble"
            closable
            @close="removeFilter(value)"
          >
            {{ $t(columns[value].name) }}
          </b-tag>
        </div>
      </div>
      <div class="level-right">
        <slot name="header-right" />
      </div>
    </div>
    <b-table
      :data="data"
      :loading="isLoading"

      :paginated="pagination.enable"
      :current-page.sync="pagination.page"
      :total="pagination.total"
      :per-page="pagination.perPage"

      :default-sort-direction="sort.defaultOrder"
      :default-sort="[sort.field, sort.order]"

      striped
      sticky-header
      hoverable
      backend-sorting
      backend-pagination

      class="no-wrap smart-table"
      :row-class="(row, index) => {
        // shuttle days list
        if (row.total && row.total_trashed && row.total === row.total_trashed) {
          return 'has-background-danger-light has-text-grey'
        }

        // shuttle registers list
        if (row.trashed) {
          return 'has-background-danger-light has-text-grey'
        }

        return ''
      }"

      @page-change="onPageChange"
      @sort="onSort"
    >
      <b-table-column v-slot="props" custom-key="actions" :sortable="false" width="110">
        <slot name="action-column" :row="props.row" />
      </b-table-column>
      <template v-for="(scolumn, index) in columns" :visible="checkPermission(scolumn.permission)">
        <b-table-column
          :key="index"
          :label="scolumn.name" :field="index" :centered="scolumn.centered" :sortable="scolumn.sortable" :number="scolumn.number"
          :width="scolumn.width"
          :searchable="false"
          v-bind="scolumn"
        >
          <template slot="header" slot-scope="{ column }">
            <span v-if="scolumn.searchable">
              <b-dropdown v-if="scolumn.searchable.type === 'input'" aria-role="menu" class="search-dropdown" :close-on-click="false">
                <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
                <b-dropdown-item aria-role="menu-item" :focusable="false">
                  <b-input v-model="filter[index]" :placeholder="$t('search')" size="is-small" autocomplete="off" type="search" />
                </b-dropdown-item>
              </b-dropdown>

              <b-dropdown v-if="scolumn.searchable.type === 'select'" v-model="filter[index]" aria-role="list" :multiple="scolumn.searchable.multiselect">
                <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
                <b-dropdown-item
                  v-for="(value, sindex) in scolumn.searchable.values"
                  :key="sindex"
                  :value="value"
                  aria-role="listitem"
                >
                  <span v-if="scolumn.trans">{{ $t(`smartTable.${value}`) }}</span>
                  <span v-else>{{ value }}</span>
                </b-dropdown-item>
              </b-dropdown>

              <b-dropdown v-if="scolumn.searchable.type === 'roles' && roles" v-model="filter[index]" aria-role="list" :multiple="scolumn.searchable.multiselect">
                <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
                <b-dropdown-item
                  v-for="role in roles"
                  :key="`role_${role.id}`"
                  :value="role.id"
                  aria-role="listitem"
                >
                  {{ role.name }}
                </b-dropdown-item>
              </b-dropdown>

              <b-dropdown v-if="scolumn.searchable.type === 'range'" aria-role="menu" class="date-search search-dropdown" :close-on-click="false">
                <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
                <b-dropdown-item custom paddingless aria-role="menu-item" :focusable="false">
                  <b-datepicker
                    v-model="filter[index]"
                    :first-day-of-week="1"
                    :month-names="$t('dateTimePicker.monthNames')"
                    :day-names="$t('dateTimePicker.dayNames')"
                    icon="calendar-today"
                    range
                    inline
                    @input="choseDate"
                  />
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-if="scolumn.trans_column">
              {{ $t(column.label) }}
            </span>
            <span v-else>{{ column.label }}</span>
          </template>

          <template v-slot="props">
            <span :class="{'tag': scolumn.tagged}" :data-label="String(props.row[index])">
              <b-tooltip
                :label="String(props.row[index])"
                :active="(scolumn.tooltip == true)"
                type="is-dark"
                position="is-bottom"
              >
                <span v-text="echoColumn(scolumn, props.row, index)" />
              </b-tooltip>
            </span>
          </template>
        </b-table-column>
      </template>

      <template #footer>
        <slot name="footer" :data="data" />
      </template>

      <template slot="bottom-left">
        <div v-if="pagination.total > 0" class="has-text-left subtitle help">
          {{ $t('paginationShowing', [pagination.total.toLocaleString(), ((pagination.page * pagination.perPage) - pagination.perPage) + 1, ((pagination.page * pagination.perPage) > pagination.total ? pagination.total : (pagination.page * pagination.perPage)) ]) }}
        </div>
      </template>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>{{ $t('fetching') }}...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="table" size="is-large" />
            </p>
            <p>{{ $t('nodata') }}</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import moment from 'moment'
import $ from 'jquery'
import download from 'downloadjs'
import { mapState } from 'vuex';

export default {
  name: 'SmartTable',
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    dataUrl: {
      type: String,
      default: ''
    },
    sort: {
      type: Object,
      default: () => {
        return {
          defaultOrder: 'desc',
          field: 'id',
          order: 'desc',
        }
      }
    },
    filters: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      pagination: {
        total: 0,
        page: 1,
        count: 0,
        perPage: 30,
        enable: false
      },
      isLoading: true,
      firstLoad: false,
      inputTrigger: false,
      columns: {},
      data: [],
      filtered: [],
      filter: {},
      excel: false,
    }
  },
  computed: {
    ...mapState(['roles', 'departments']),
  },
  watch: {
    filter: {
      handler () {
        if (this.firstLoad) {
          this.filterAction()
        } else {
          this.firstLoad = true
        }
      },
      deep: true
    }
  },
  created () {
    if (this.roles.length == 0) {
      if (this.checkPermission('create_user')) {
        this.$store.dispatch('getRoles');
        this.$store.dispatch('getDepartments');
      }
    }
    // this.filter = this.defaultFilters()
  },
  mounted () {
    this.loadData();
  },
  methods: {
    filterAction: debounce(function () {
      this.filterShow()
      this.loadData();
    }, 350),
    generateParams () {
      const pageSort = [
        `sort_by=${this.sort.field}`,
        `sort_order=${this.sort.order}`,
        `page=${this.pagination.page}`
      ];

      if (this.filters) {
        for (const [key, value] of Object.entries(this.filters)) {
          pageSort.push(`${key}=${value}`)
        }
      }

      let colmnValue;
      const filters = this.$_.compact(this.$_.keys(this.filter).map((key) => {
        colmnValue = this.filter[key];
        if (this.$_.isArray(colmnValue)) {
          if (this.columns[key].searchable.type == 'range' && colmnValue.length > 1) {
            colmnValue = [
              moment(colmnValue[0]).format('YYYY-MM-DD'),
              moment(colmnValue[1]).format('YYYY-MM-DD'),
            ]
          }
        } else {
          colmnValue = this.$_.replace(colmnValue, '#', '');
        }

        if (colmnValue.length > 0) {
          return [`${key}=${colmnValue}`];
        }
      }));

      const params = pageSort.concat(...filters);
      return params.join('&');
    },
    loadData () {
      let params = this.generateParams();
      let responseType = 'json'

      if (this.excel) {
        params += '&excel=1'
        responseType = 'blob'
      } else {
        this.$emit('update:loading', true)
      }

      this.$http({
          url: `${this.dataUrl}?${params}`,
          method: 'GET',
          responseType: responseType,
        })
        .then(response => {
          if (this.excel) {
            const mimType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            const fileName = `export-${moment().format('DD-MM-YYYY-h-mm')}.xlsx`
            download(response.data, fileName, mimType)
            this.$emit('update:excelLoading', false)
            this.excel = false;
            return false;
          }

          this.data = [];

          if (response.data) {
            if (response.data.meta) {
              this.pagination.enable = true
              this.pagination.total = response.data.meta.total
              this.pagination.perPage = response.data.meta.per_page
              this.pagination.page = response.data.meta.current_page
              this.pagination.count = response.data.meta.to
            } else {
              this.pagination.enable = false
              this.pagination.total = 0
            }

            this.data = response.data.data
            this.columns = response.data.columns
          }

          this.$nextTick(() => {
            this.jqInputTrigger()
            $('.table-wrapper').scrollTop(0)
            if (!this.firstLoad) {
              this.filter = this.defaultFilters()
            }
          })
        })
        .catch(e => {
          const errors = this.parseError(e);
          this.$buefy.toast.open({
            message: this.$t('error') + ': <br>' + errors,
            type: 'is-danger',
            duration: 5000
          });
        })
        .finally(() => {
          this.$nextTick(() => {
            this.$emit('update:loading', false)
            this.isLoading = false;
          })
        })
    },
    filterShow () {
      this.filtered = this.$_.keys(this.$_.omitBy(this.filter, this.$_.isEmpty))
    },
    clearFilter () {
      this.filter = this.defaultFilters()
      this.filterShow()
    },
    defaultFilters () {
      let emptyFilters = {};
      if (this.columns.length == 0) {
        return false
      }
      let defaultValue = '';

      this.$_.forEach(this.columns, (column, key) => {
        defaultValue = '';

        if (column.searchable && !column.searchable == false) {
          if (
            (column.searchable.type == 'select' && column.searchable.multiselect) ||
            column.searchable.type == 'range'
          ) {
            defaultValue = [];
          }

          defaultValue
          emptyFilters[key] = defaultValue;
        }
      });

      return emptyFilters
    },
    onSort (field, order) {
      this.pagination.page = 1
      this.sort.field = field
      this.sort.order = order

      this.loadData()
    },
    onPageChange (page) {
      this.pagination.page = page
      this.loadData()
    },
    jqInputTrigger () {
      if (this.inputTrigger) return;

      $('.dropdown-trigger').click(function (e) {
        e.stopPropagation()
        $(this).parent().find('input, select').first().focus()
      })
      $('.search-dropdown input').keypress(function (e) {
        if (e.which == '13') {
          $('body').trigger('click')
        }
      });
      $('.dropdown').click(function (e) {
        e.stopPropagation()
      })
      this.inputTrigger = true;
    },
    removeFilter (filter) {
      const defaultFilters = this.defaultFilters();
      this.filter[filter] = defaultFilters[filter];
      this.filterShow()
    },
    echoColumn (scolumn, data, index) {
      let rValue = '';
      let prefix = '';

      if (scolumn.prefix) {
        prefix = scolumn.prefix;
      }

      if (scolumn.trans) {
        rValue = this.$t(`smartTable.${data[index]}`)
      } else if (index === 'total' && data.total && data.total_trashed) {
        rValue = data.total - data.total_trashed
      } else {
        rValue = data[index]
      }

      if (scolumn.filters) {
        scolumn.filters.forEach(filter => {
          rValue = this.$options.filters[filter](rValue);
        });
      }

      if ((!rValue && rValue != 0) || rValue == null) rValue = ' ';

      return `${prefix}${rValue}`;
    },
    choseDate () {
      setTimeout(function () {
        $('body').trigger('click');
      }, 250);
    },
    excelExport () {
      this.excel = true
      this.loadData()
    }
  }
}
</script>
